<template>
    <div>
        <div class="r-option-place r-center-flex r-isWrap">
            <InputCustom
                :rightIcon="true"
                :withError="false"
                v-model="request.search" 
                @keyup="setTime()" 
                @keydown="clearTime()" 
                @keydown.tab="setTime()" 
                @keyup.tab="clearTime()" 
                @keypress.enter="getData()" 
                placeholder="Search . . ."
                size='md'
                >
                <template #rightIcon>
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require('@/assets/icons/others/search.svg')" alt="search">
                    </div>
                </template>
            </InputCustom>

            <div class="y-inputField r-mr-auto">
                <div class="y-inputPlace y-hasIcon-right">
                    <div class="y-select-hasIcon">
                        <select class="y-select" v-model="status">
                            <option class="y-option" value="Online">Online</option>
                            <option class="y-option" value="Offline">Offline</option>
                        </select>
                        <div class="y-select-icon">
                            <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                        </div>
                    </div>
                </div>
            </div>

            <ButtonCustom
                class="r-mr-auto"
                @click="updateAllStatus()"
            >
                Update Status
            </ButtonCustom>

            <ButtonCustom
                class="r-ml-auto"
                @click="openModal()"
            >
                Tambah Data
            </ButtonCustom>
        </div>

        <TableCustom 
            :columns="columns" 
            :request="request" 
            :fetchData="fetchData"
            :data="data"
            @setSortBy="setSortBy"
            @setShow="setShow" 
            @getData="getData"
            @mouseleave="popup = null"
            @togglePopup="togglePopup"
        >
            <template #index="props">
                {{curNumber(props.index)}}
            </template>

            <template #updated_at="props">
                {{convertDateTime(props.row.updated_at)}}
            </template>

            <template #updated_by="props">
                {{props.row.updated_by}}
            </template>

            <template #name="props">
                {{props.row.name}}
            </template>

            <template #logo="props">
                <img class="r-isExpand r-wd-100-max" :src="`${logo_url}${props.row.logo_path}`">
            </template>

            <template #potongan="props">
                {{props.row.potongan}}%
            </template>
            
            <template #type="props">
                {{props.row.type}}
            </template>
            
            <template #status="props">
                <ButtonCustom
                    :type="bankStatus(props.row.status).type"
                    :disabled="statusLoading != null"
                    @click.stop="updateStatus(props.row)"
                >
                    <img :src="require('@/assets/loadingDot.svg')" alt style="width: 50px;" v-if="statusLoading == props.row.id" />
                    <span v-else>
                        {{bankStatus(props.row.status).label}}
                    </span>
                </ButtonCustom>
            </template>

            <template #action="props">
                <div class="r-menu">
                    <img class="r-is-clickable" :src="require('@/assets/icons/others/more.svg')" @click.prevent="popup = curNumber(props.index)">
                    <div class="r-menu-items" v-if="popup == curNumber(props.index)" @mouseleave="popup = -1">
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="setData(props.row)">Ubah</div>
                        <div class="r-menu-item y-red-text r-center-flex"  @click.prevent="openDeleteModal(props.row)">Hapus</div>
                    </div>
                </div>
            </template>
        </TableCustom>

        <InputModal 
            v-if="modal == 'inputModal'" 
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        ></InputModal>

        <DeleteModal
            v-if="modal == 'deleteModal'" 
            @resetData="resetData"
            @getData="getData"
        >
        </DeleteModal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import InputModal from '@/components/Bank/InputModal'
import DeleteModal from '@/components/Bank/DeleteModal'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {
        InputModal,
        DeleteModal
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            data: 'bank/getData',
            request: 'bank/getRequest',
            bankStatus: 'bank/getBankStatus',
            item: 'bank/getItem',
        }),

    },
    data: () => ({
        columns: [
            {
                label: 'No',
                isSortable: false,
                fieldName: 'index',
                class: 'r-table-firstColumn'
            },
            {
                label: 'Diupdate Pada',
                isSortable: true,
                fieldName: 'updated_at',
                class: ''
            },
            {
                label: 'Diupdate Oleh',
                isSortable: true,
                fieldName: 'updated_by',
                class: ''
            },
            {
                label: 'Nama',
                isSortable: true,
                fieldName: 'name',
                class: ''
            },
            {
                label: 'Logo',
                isSortable: false,
                fieldName: 'logo',
                class: ''
            },
            {
                label: 'Potongan',
                isSortable: true,
                fieldName: 'potongan',
                class: ''
            },
            {
                label: 'Tipe',
                isSortable: true,
                fieldName: 'type',
                class: ''
            },
            {
                label: 'Status',
                isSortable: false,
                fieldName: 'status',
                class: ''
            },
            {
                label: 'Action',
                isSortable: false,
                fieldName: 'action',
                class: 'r-table-actionColumn'
            }
        ],
        modal: null,
        inputType: 'Tambah',
        statusLoading: null,
        status: 'Online',
    }),
    methods: {
        ...mapActions ({
            resetItem: 'bank/resetItem',
            resetRequest: 'bank/resetRequest',
            setItem: 'bank/setItem',
            getBanks: 'bank/fetchData',
            updateStatusBank: 'bank/updateStatus',
            updateAllStatusBank: 'bank/updateAllStatus',
            resetFileData: 'bank/resetFileData',
            setFileData: 'bank/setFileData',
        }),
        openModal(type = 'Tambah') {
            this.modal = 'inputModal'
            this.inputType = type
        },
        setData(data) {
            const item = this.itemFormat(data)
            this.setItem(item)
            this.openModal('Ubah')
        },
        openDeleteModal(data) {
            const item = this.itemFormat(data)
            this.setItem(item)
            this.modal = 'deleteModal'
        },
        itemFormat(data){
            const fileData = {
                url: `${this.logo_url}${data.logo_path}`,
                name: data.file_name,
                size: data.file_size,
                isImage: data.mime_type.split('/')[0] == 'image',
                isVideo: data.mime_type.split('/')[0] == 'video',
                file: null
            }
            this.setFileData(fileData)
            const item = {
                id: data.id,
                name: data.name,
                type: data.type,
                potongan: data.potongan
            }
            return item
        },
        resetData() {
            this.modal = null
            this.statusLoading = null
            this.resetItem()
            this.resetRequest()
            this.resetFileData()
        },
        async getData(type = 0) {
            this.fetchData = true
            if (type === 0) {
                this.request.page = 1
            } else {
                this.request.page += type
            }
            await this.getBanks()
            this.fetchData = false
            const response = this.getResponse('bank')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async initData() {
            const breadcrumb = [{
                name: 'Bank',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Bank')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
        },
        async updateStatus(item) {
            this.statusLoading = item.id
            this.loading = true
            await this.updateStatusBank(item)
            this.loading = false
            const response = this.getResponse('bank')
            this.showSnackbar({
                type: response.status == 1 ? 'success': 'error',
                text: response.msg
            })
            this.resetData()
            this.getData()
        },
        async updateAllStatus() {
            this.loading = true
            this.item.status = this.status
            await this.updateAllStatusBank(this.item)
            this.loading = false
            const response = this.getResponse('bank')
            this.showSnackbar({
                type: response.status == 1 ? 'success': 'error',
                text: response.msg
            })
            this.resetData()
            this.getData()
        }
    }
}
</script>