<template>
    <Modal label="Data Bank">

        <UploadFile
            label="Logo Bank"
            :fileData="fileData"
            :error="error.logo"
            :errorMsg="errorMsg.logo"
            @setFileData="onSetFileData"
            @resetError="resetError('logo')"
            @setError="setError($event)"
            :accept="accept"
        >

        </UploadFile>

        <InputCustom
            v-model="item.name"
            :error="error.name"
            :errorMsg="errorMsg.name"
            @onFocus="resetError('name')"
            placeholder="Nama"
            label="Nama Bank"
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.potongan" 
            :error="error.potongan"
            :errorMsg="errorMsg.potongan"
            @onFocus="resetError('potongan')"
            placeholder="Potongan"
            label="Potongan Bank (Opsional)"
        >
        </InputCustom>

        <div class="y-inputField">
            <div class="y-labelText">
                Tipe Bank
            </div>
            <div class="y-inputPlace y-hasIcon-right">
                <div class="y-select-hasIcon" style="width: 100%">
                    <select class="y-select" v-model="item.type">
                        <option class="y-option" value="Bank">Bank</option>
                        <option class="y-option" value="Wallet">Wallet</option>
                        <option class="y-option" value="Pulsa">Pulsa</option>
                    </select>
                    <div class="y-select-icon">
                        <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                    </div>
                </div>
            </div>
            <div
                class="y-errorText y-red-text"
                :style="error.type ? 'opacity: 1 !important;': ''"
            >
                {{errorMsg.type}}
            </div>
        </div>
        
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                {{inputType}}
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
import validation from '@/mixins/function/validation'
import UploadFile from '@/components/Helper/UploadFile'

export default {
    mixins: [
        common,
        validation
    ],
    components: {
        Modal,
        UploadFile
    },
    mounted() {
        this.loadBanks = true
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            item: 'bank/getItem',
            fileData: 'bank/getFileData',
        })
    },
    data: () => ({
        loadBanks: false,
        accept: 'image/*',
        error: {
            name: null,
            type: null,
            potongan: null,
            logo: null
        },
        errorMsg: {
            name: 'Tidak boleh kosong',
            type: 'Tidak boleh kosong',
            potongan: 'Tidak boleh kosong',
            logo: 'Tidak boleh kosong'
        },
        loading: false,
    }),
    methods: {
        ...mapActions({
            store: 'bank/store',
            update: 'bank/update',
            setFileData: 'bank/setFileData',
        }),
        resetData() {
            this.$emit('resetData')
        },
        onSetFileData(data){
            this.setFileData(data)
        },
        validation() {
            var error = 0
            var field = ['name','type']
            for (var i = 0; i < field.length; i++){
                if(this.item[field[i]] === null || this.item[field[i]]  === ''){
                    this.error[field[i]] = true
                    error += 1;
                }else{
                    this.error[field[i]] = false
                }
            }
            if(this.item.potongan !== null && this.item.potongan !== ""){
                if(!(/^\d+$/.test(this.item.potongan))){
                    this.error.potongan = true
                    this.errorMsg.potongan = "Hanya boleh angka"
                    error += 1;
                } else if (this.item.potongan < 0 || this.item.potongan > 100){
                    this.error.potongan = true
                    this.errorMsg.potongan = "Range potongan 1 - 100"
                    error += 1;
                } else{
                    this.error.potongan = false
                }
            }
            return error
        },
        prepareData(data){
            const formData = new FormData()
            formData.append('name', data.name)
            formData.append('type', data.type)
            formData.append('potongan', data.potongan == '' ? data.potongan : 0)
            if(this.fileData.file) formData.append('logo',this.fileData.file)
            return formData
        },
        async sendData() {
            if (this.validation() === 0) {
                const payload = this.prepareData(this.item)
                this.loading = true
                if (this.inputType == 'Tambah') {
                    await this.store(payload)
                } else {
                    await this.update({payload, id: this.item.id})
                }
                this.loading = false
                const response = this.getResponse('bank')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        }
    }
}
</script>